import React, { useState } from 'react'
import styled from 'styled-components'
import { send } from 'emailjs-com'
import { StyledButton } from './Button'
import FlexContainer from './lib/FlexContainer'
import breakpoints from './../commons/breakpoints'
import { validateInput } from '../utilities/utils'

const Form = styled(FlexContainer)`
  & div,
  input,
  textarea,
  button {
    width: 100%;
  }

  @media ${breakpoints.device.tablet} {
    flex-direction: column;
  }
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const StyledInput = styled.input`
  padding: 0.8rem 0.5rem;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.greyishPurple};

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.yellow};
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

const SubmitButton = styled(StyledButton)`
  padding: 0.8rem;
  background: none;
`

const Error = styled.small`
  color: ${({ theme }) => theme.colors.red};
`

const Toast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 11;
  padding: 1rem;
`

const ToastInner = styled.div`
  max-width: 30rem;
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 2px;
  border-left: 5px solid ${({ theme }) => theme.colors.basePurple};
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
`

const ToastButton = styled.button`
  padding: 0.3rem 1.5rem;
  margin-top: 1rem;
`

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sendResponse, setSendResponse] = useState('')

  const handleChange = ({ target }) => {
    const { name, value } = target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: null })
  }

  const handleBlur = ({ target }) => {
    const { name, value } = target
    const error = validateInput(name, value)
    setTouched({ ...touched, [name]: true })
    setErrors({ ...errors, [name]: error })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formValidation = Object.keys(formData).reduce(
      (acc, key) => {
        const newError = validateInput(key, formData[key])
        const newTouched = { [key]: true }

        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },

          touched: {
            ...acc.touched,
            ...newTouched,
          },
        }
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      }
    )

    setErrors(formValidation.errors)
    setTouched(formValidation.touched)

    if (
      Object.values(formValidation.errors).every((err) => !err) &&
      Object.values(formValidation.touched).length ===
        Object.values(formData).length &&
      Object.values(formValidation.touched).every((t) => t === true)
    ) {
      setIsSubmitting(true)
      const payload = {
        from_name: formData.name,
        to_name: 'Surafel',
        message: formData.message,
        reply_to: formData.email,
      }

      try {
        const sendMessage = await send(
          'service_lu0d78w',
          'template_rabz0fo',
          payload,
          'user_ZzLeNeppxYFdzcM93mZYL'
        )
        setSendResponse(() => sendMessage.status)
        setFormData({
          name: '',
          email: '',
          message: '',
        })
      } catch (error) {
        console.log(error)
        setSendResponse(() => error.status)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const ToastNotification = () => {
    return (
      <Toast>
        <ToastInner>
          <p>
            {sendResponse === 200
              ? "Message sent successfully. I'll get back to you. Thanks."
              : 'Sorry, something went wrong. Please try again.'}
          </p>

          <ToastButton onClick={() => setSendResponse('')}>OK</ToastButton>
        </ToastInner>
      </Toast>
    )
  }

  return (
    <>
      <Form as='form' noValidate onSubmit={handleSubmit}>
        <FormGroup>
          <StyledInput
            type='text'
            placeholder='Name'
            name='name'
            value={formData.name}
            aria-label='name'
            onChange={handleChange}
            onBlur={handleBlur}
            required
            disabled={isSubmitting}
          />
          {touched.name && <Error>{errors.name}</Error>}
        </FormGroup>

        <FormGroup>
          <StyledInput
            type='email'
            placeholder='Email Address'
            name='email'
            value={formData.email}
            aria-label='email address'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
          />
          {touched.email && <Error>{errors.email}</Error>}
        </FormGroup>

        <FormGroup>
          <StyledInput
            as='textarea'
            name='message'
            rows='10'
            placeholder='Message'
            value={formData.message}
            onChange={handleChange}
            onBlur={handleBlur}
            aria-label='message'
            disabled={isSubmitting}
          />
          {touched.message && <Error>{errors.message}</Error>}
        </FormGroup>

        <SubmitButton as='button' type='submit' disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </SubmitButton>
      </Form>
      {sendResponse && <ToastNotification />}
    </>
  )
}

export default ContactForm

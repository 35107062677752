import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { getCurrentYear, toTitleCase } from './../utilities/utils'
import breakpoints from '../commons/breakpoints'
import Container from './lib/Container'

// *** Styles ***
const StyledFooter = styled.footer`
	background: ${(props) => props.theme.colors.lightPurple};
	flex-shrink: 0;
`

const Wrapper = styled(Container)`
	font-size: 0.9rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.deepBlue};
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	@media ${breakpoints.device.tablet} {
		justify-content: space-between;
		flex-direction: row;
	}
`

const Paragraph = styled.p`
	order: 2;
	margin-top: 1rem;

	@media ${breakpoints.device.tablet} {
		order: 1;
		margin-top: 0;
	}
`
const SocialLinks = styled.ul`
	display: flex;
	order: 1;

	@media ${breakpoints.device.tablet} {
		order: 2;
	}
`

const SocialItem = styled.li`
	margin: 0 0.5rem;
`

const Footer = () => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					author {
						socialProfiles {
							linkedIn
							github
						}
					}
				}
			}
		}
	`)

	const thisYear = getCurrentYear()

	return (
		<StyledFooter>
			<Wrapper>
				<Paragraph>
					&copy;{thisYear} {site.siteMetadata.title}
				</Paragraph>
				<SocialLinks>
					{Object.entries(site.siteMetadata.author.socialProfiles).map(
						([key, value]) => (
							<SocialItem key={key}>
								<a href={value}>{toTitleCase(key)}</a>
							</SocialItem>
						)
					)}
				</SocialLinks>
			</Wrapper>
		</StyledFooter>
	)
}

export default Footer

import React from 'react'

const MessagingIllustration = (props) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 622.5 856.287'>
			<g
				transform='translate(263.689 532.995)'
				strokeMiterlimit='10'
				strokeWidth='5'>
				<path
					d='M239.916 13.677a13.677 13.677 0 01-13.677 13.677H13.68a13.677 13.677 0 110-27.353h212.56a13.677 13.677 0 0113.676 13.676z'
					fill='#fff'
					stroke='#ffb000'
				/>
				<path
					d='M163.888 49.387H83.879a9.683 9.683 0 01-9.683-9.683v-12.35h99.368v12.357a9.683 9.683 0 01-9.676 9.676zM134.332 216.981H113.4V49.387h20.932z'
					fill='#fff'
					stroke='#ffb000'
				/>
				<rect
					width='40.462'
					height='43.232'
					rx='12.24'
					transform='rotate(180 72.052 130.107)'
					fill='#fff'
					stroke='#ffb000'
				/>
				<path
					d='M216.509 299.151a38.937 38.937 0 00-38.937-38.937h-107.4a38.937 38.937 0 00-38.937 38.938'
					fill='none'
					stroke='#6562cf'
				/>
				<circle
					cx='15.598'
					cy='15.598'
					r='15.598'
					transform='translate(201.615 288.181)'
					fill='#fff'
					stroke='#ffb000'
				/>
				<circle
					cx='15.598'
					cy='15.598'
					r='15.598'
					transform='translate(14.648 288.181)'
					fill='#fff'
					stroke='#ffb000'
				/>
				<circle
					cx='15.598'
					cy='15.598'
					r='15.598'
					transform='translate(108.832 288.181)'
					fill='#fff'
					stroke='#ffb000'
				/>
			</g>
			<path
				d='M184.781 771.045c-6.961 16.515-13.3 30.055-18.135 38.938-3.6 6.606-9.861 45.133-42.794 29.61-1.163-.547 12.993-4.2 17.458-36.072q2.346-16.761 4.3-32.332c12.885.875 29.228.341 39.171-.144z'
				fill='#ffcfc9'
			/>
			<path
				d='M177.663 855.115s13.26-45.885-4.472-52.888c-5.662-2.236-12.528 3.419-14.361 22.731-1.607 16.884-26.273 19.5-28.6 8.589 0 0-30.239 6.155-30.239 21.568z'
				fill='#3f4d79'
			/>
			<path
				d='M193.733 770.525s-36.626 2.619-55.89-.123c0 0-25.575-266.8-21.753-294.247 4.6-33.022 114.535-62.29 171.369-71.57l10.469-47.417s76.89-1.368 120.307-1.368c0 0 46.5 82.744 41.714 137.451S354.9 535.54 220.998 535.54c0 0 40.825 82.408-27.265 234.985z'
				fill='#6562cf'
			/>
			<path
				d='M305.307 772.344a158.587 158.587 0 01-30.608-.246c3.419 13.3 6.578 25.986 9.088 37.317 1.621 7.276-8.794 17.1-11.762 24.242l.232-.109c13.677-2.181 32.024 10.77 30.137-9.444s1.012-25.254 2.913-51.76z'
				fill='#ffcfc9'
			/>
			<path
				d='M301.984 809.23c4.7 0 12.35 8.507 11.591 22.129a79.407 79.407 0 01-4.992 23.756h-52.881c.93-14.668 16.556-21.568 16.556-21.568 13.677-2.181 31.34 10.77 29.453-9.444z'
				fill='#3f4d79'
			/>
			<path
				d='M343.383 430.228S226.837 451.29 213.598 486.2s48.518 284.3 48.518 284.3c35.806 5.471 54.173 0 54.173 0 37.242-170.794-6.394-227.443-6.394-227.443 86.785-3.686 141.554 4.548 150.061-49.769 2.715-17.243-16.679-83.208-116.573-63.06z'
				fill='#6562cf'
			/>
			<path
				d='M341.332 430.228S224.786 451.29 211.547 486.2s48.518 284.3 48.518 284.3'
				fill='none'
				stroke='#fff'
				strokeMiterlimit='10'
				strokeWidth='5'
			/>
			<g>
				<path
					d='M315.709 318.476c6.927-15.66-23.825-36.243-55.295-28.037 0 0 10.613-126.291 35.484-142.2 24.139-15.441 29.952 57.285 30.773 75.044-.028-.004 4.438 68.701-10.962 95.193z'
					fill='#eaeeff'
				/>
				<path
					d='M313 322.282c15.243-13.677-16.275-41.509-52.587-31.846-22.717 3.529-111.027 22.355-119.329 26.67-8.11 3.617-31.532 15.4-34.513 18.976-3.761 4.513-4.336 16.453-4.1 18.026s3.317 9.758 3.317 9.758a5.908 5.908 0 005.163-7.044c-.9-7.652 5.908-10.025 10.463-12.077 1.9-.848 6.838-.5 7.707 2.38s-6.332 6.367-7.522 8.452c-1.771 3.159.28 6.695.321 6.77s10.982-5.895 13.1-7.016c1.942-1.033 11.071-11.687 14.942-21.076.019-.003 136.28 12.074 163.038-11.973z'
					fill='#ffc0b7'
				/>
			</g>
			<g>
				<path
					d='M296.089 80.283c-2.564-6.23-13.191-38.466 4.028-52.389S340.046-.314 367.522 1.909s35.135 38.808 50.706 44.689 34.568-1.094 52.518 15.044-.684 41.543 17.609 55.1 35.922-3.6 54.249 20.365c21.007 27.476-9.676 59.227 20.146 61.962 32.974 3.023 52.867 58.851 38.712 95.908H429.696C320.023 278.034 296.089 80.283 296.089 80.283z'
					fill='#ffb000'
				/>
				<path
					d='M307.926 25.966c16.74-14.832 36.2-24.7 63.733-23.353s38.117 32.017 53.873 37.515 35.621-2.263 54.1 13.677.684 44.258 19.455 57.545 35.778-4.862 54.9 19.093c21.931 27.47-7.673 58.81 22.225 60.629 33.057 2.017 54.666 43.813 38.062 91.2'
					fill='none'
					stroke='#ffb000'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
			</g>
			<g transform='translate(263.964 132.487)'>
				<path
					d='M33.446 224.2s-9.738-79.366-14.887-83.523c-11.564-9.341-20.761-22.738-15.94-41.235C5.354 89 27.34 19.782 32.019 15.385S67.446 0 67.446 0h86.847c9.888 3.3 40.934 14.463 48.354 25.828 36.243 55.5 47.834 144.522 47.834 144.522h-45.065s-21.691-48.682-29.4-49.236-22.163 102.4-22.163 102.4-49.412 13.715-120.407.686z'
					fill='#eaeeff'
				/>
				<path
					d='M32.761 224.202s-9.738-79.363-14.883-83.524C6.314 131.337-3.567 117.257 1.254 98.759M94.354 9.642s-40 76.33-30.089 221.4'
					fill='none'
					stroke='#fff'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
				<circle
					cx='5.272'
					cy='5.272'
					r='5.272'
					transform='translate(84.302 63.72)'
					fill='none'
					stroke='#fff'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
				<circle
					cx='5.272'
					cy='5.272'
					r='5.272'
					transform='translate(77.074 102.384)'
					fill='none'
					stroke='#fff'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
				<circle
					cx='5.272'
					cy='5.272'
					r='5.272'
					transform='translate(73.21 141.184)'
					fill='none'
					stroke='#fff'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
			</g>
			<g>
				<path
					d='M509.446 324.48c-22.313 23.462-166.575-7.912-166.575-7.912s-7.522 11.885-16.33 12.309-17.144-2.687-21.616 1.087-8.986 10.346-12.487 11.023a39.8 39.8 0 01-12.637.533 120.042 120.042 0 015.156-12.1c-2.66 2.154-5.963 6.107-7.9 7.755a4.9 4.9 0 01-6.045.5s5.778-8.821 8.056-11.181 9.259-8.281 13.13-9.628c4.575-1.956 31.361-15.079 44.73-17.253 14.176-2.3 128.978-10.025 128.978-10.025 41.522-.034 62.277 15.198 43.54 34.892z'
					fill='#ffcfc9'
				/>
				<path
					d='M284.115 340.147s8.288-14.408 11.064-15.933c4.889-2.694 11.27-6.237 11.27-6.237M289.846 339.887s7.324-11.851 10.1-13.376c4.889-2.694 11.27-6.237 11.27-6.237'
					fill='none'
					stroke='#ffb0af'
					strokeLinecap='round'
					strokeMiterlimit='10'
					strokeWidth='3'
				/>
			</g>
			<g>
				<path
					d='M366.579 79.209s12.083 37.891 51.65 53.278c0 0-55.5 24.18-86.847 0V80.303z'
					fill='#ffcfc9'
				/>
				<path
					d='M339.396 137.419a45.463 45.463 0 01-7.987-4.93V80.303l35.17-1.094s.342 1.067 1.094 2.92c3.586 15.99-6.121 49.181-28.277 55.29z'
					fill='#ffc0b7'
				/>
			</g>
			<path
				d='M365.655 85.047c-.362 12.459-23.811 41.769-36.633 41.769-10.627 0-30.465-32.051-32.968-46.5-2.564-14.867.034-41.235 21.233-41.6 13.1-.226 19.831 12.083 23.763 26.355 4.787 17.226 19.476 13.362 19.476 13.362s4.206-9.574 10.066-4.787-4.937 11.401-4.937 11.401z'
				fill='#ffcfc9'
			/>
			<g>
				<path
					d='M25.031 855.116L59.223 384.07l34.188 472.037'
					fill='none'
					stroke='#ffb000'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
				<path
					d='M22.029 384.07h450.189a10.613 10.613 0 0010.613-10.6 10.613 10.613 0 00-10.613-10.613H22.029z'
					fill='#fff'
				/>
				<path
					d='M22.029 384.07h450.189a10.613 10.613 0 0010.613-10.6h0a10.613 10.613 0 00-10.613-10.613H22.029'
					fill='none'
					stroke='#ffb000'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
			</g>
			<g transform='translate(0 202.73)'>
				<path
					d='M200.353 0H18.679A12.439 12.439 0 007.013 16.727L52.829 141.18H265.57L218.18 12.435A19 19 0 00200.352-.004z'
					fill='none'
					stroke='#000'
					strokeMiterlimit='10'
				/>
				<path
					d='M201.988 0H19.643C11.211 0 5.358 8.938 8.271 17.369l39.662 115.165a12.439 12.439 0 0011.625 8.646h203.975L219.378 12.92A18.614 18.614 0 00201.988 0z'
					fill='#fff'
				/>
				<path
					d='M194.465 8.623H12.12A12.118 12.118 0 00.748 24.932L40.41 133.06a12.384 12.384 0 0011.625 8.124H256.01l-44.155-120.43a18.532 18.532 0 00-17.39-12.131z'
					fill='#e2e2ff'
				/>
				<path
					d='M52.405 141.184h347.976a18.293 18.293 0 0118.293 18.293H70.698a18.293 18.293 0 01-18.293-18.293h0z'
					fill='#fff'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='6'
				/>
				<path
					d='M200.353 0H18.679A12.439 12.439 0 007.013 16.727L52.829 141.18H265.57L218.18 12.435A19 19 0 00200.352-.004z'
					fill='none'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
				<ellipse
					cx='11.646'
					cy='14.367'
					rx='11.646'
					ry='14.367'
					transform='rotate(-48 141.771 -77.27)'
					fill='#ffb000'
				/>
				<ellipse
					cx='11.646'
					cy='14.367'
					rx='11.646'
					ry='14.367'
					transform='rotate(-48 138.973 -84.141)'
					fill='none'
					stroke='#6562cf'
					strokeMiterlimit='10'
					strokeWidth='5'
				/>
			</g>
		</svg>
	)
}

export default MessagingIllustration

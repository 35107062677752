const theme = {
	colors: {
		basePurple: '#6a49ff',
		lightPurple: '#e2ddff',
		greyishPurple: '#e1e0e7',
		copyLight: '#f9f9fa',
		white: '#ffffff',
		deepBlue: '#213d75',
		copyMain: '#403e47',
		yellow: '#ffb000',
		lightYellow: '#ffecc5',
		red: '#e65d42',
	},

	gradient:
		'linear-gradient(to bottom, #ffffff, #faf8ff, #f4f2ff, #efebff, #e9e5ff, #e9e5ff, #e9e5ff, #e9e5ff, #efebff, #f4f2ff, #faf8ff, #ffffff);',
}

export default theme

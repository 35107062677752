import React from 'react'

const MenuIcon = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
			<path d='M3 6h18v2H3V6m0 5h18v2H3v-2m0 5h18v2H3v-2z' fill='#213d75' />
		</svg>
	)
}

export default MenuIcon

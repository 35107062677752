import React from 'react'
import styled from 'styled-components'
import Section from './lib/Section'
import Container from './lib/Container'
import SectionTitle from './lib/SectionTitle'
import FlexContainer from './lib/FlexContainer'
import MessagingIllustration from './illustrations/Mesaging'
import breakpoints from './../commons/breakpoints'
import ContactForm from './ContactForm'

const ContactSection = styled(Section)`
	position: relative;
	overflow: hidden;
	margin-top: 4rem;

	&::after {
		content: '';
		width: 100%;
		height: 75%;
		position: absolute;
		bottom: -10%;
		z-index: -1;
		background: ${({ theme }) => theme.colors.lightPurple};
		transform: skewY(-5deg);
	}

	@media ${breakpoints.device.tablet} {
		&::after {
			height: 60%;
			bottom: -20%;
		}
	}
`
const Paragraph = styled.p`
	max-width: 40rem;
	margin-bottom: 2rem;
`

const FormBox = styled.div`
	box-shadow: 0 0 10px rgba(20, 0, 109, 0.1);
	background: ${({ theme }) => theme.colors.white};
	padding: 2rem 1rem;
	width: 100%;
	max-width: 30rem;
	order: 2;

	@media ${breakpoints.device.tablet} {
		padding: 3rem;
		flex-basis: 50%;
		max-width: 40rem;
		order: 1;
	}
`

const IllustrationBox = styled.div`
	width: 70%;
	max-width: 20rem;
	order: 1;
	margin-bottom: 1rem;

	@media ${breakpoints.device.tablet} {
		flex-basis: 35%;
		max-width: 30rem;
		order: 2;
		margin-bottom: 0;
	}
`

const Contact = (props) => {
	return (
		<ContactSection id='contact'>
			<Container>
				<SectionTitle title='Contact me' />
				<Paragraph>
					Thanks for taking the time to reach out. Lets make something great, how can I help you?
				</Paragraph>

				<FlexContainer>
					<FormBox>
						<ContactForm />
					</FormBox>
					<IllustrationBox>
						<MessagingIllustration />
					</IllustrationBox>
				</FlexContainer>
			</Container>
		</ContactSection>
	)
}

export default Contact

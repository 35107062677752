import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import MenuIcon from './icons/Menu'
import CloseIcon from './icons/Close'
import Container from './lib/Container'
import FlexContainer from './lib/FlexContainer'
import breakpoints from '../commons/breakpoints'

const Header = styled.header`
	background: ${({ theme }) => theme.colors.copyLight};
	padding: 0.5rem 0;
	text-transform: uppercase;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.deepBlue};
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
`

const HeaderInner = styled(FlexContainer)`
	flex-direction: row;
`

const NavLogo = styled(Link)`
	font-size: 1.2rem;

	@media ${breakpoints.device.tablet} {
		font-size: 1.5rem;
	}
`

const ToggleButton = styled.button`
	width: 2rem;
	background: none;
	border: none;
	position: relative;
	z-index: 2;

	@media ${breakpoints.device.tablet} {
		width: 2.5rem;
	}
`

const MenuLinks = styled.ul`
	padding-top: 10%;
	position: fixed;
	top: 0;
	right: 0;
	width: ${({ expand }) => (expand ? '100%' : '0.01px')};
	height: 100%;
	overflow: hidden;
	background: ${({ theme }) => theme.colors.copyLight};
	text-align: center;
	transition: 0.2s;
`

const MenuItem = styled.li`
	margin: 1rem 0;

	@media ${breakpoints.device.tablet} {
		font-size: 1.5rem;
	}
`

const NavBar = (props) => {
	const { site } = useStaticQuery(graphql`
		query MenuQuery {
			site {
				siteMetadata {
					menuLinks {
						link
						title
					}
				}
			}
		}
	`)

	const [isOpen, setIsOpen] = useState(false)

	return (
		<Header>
			<Container as='nav'>
				<HeaderInner>
					<NavLogo to='/'>SH.</NavLogo>
					<ToggleButton
						title='Menu'
						aria-label='toggle button'
						onClick={() => setIsOpen((cs) => !cs)}>
						<span>{isOpen ? <CloseIcon /> : <MenuIcon />}</span>
					</ToggleButton>
					<MenuLinks onClick={() => setIsOpen(false)} expand={isOpen}>
						{site.siteMetadata.menuLinks.map(({ link, title }) => (
							<MenuItem key={title}>
								<Link to={link}>{title}</Link>
							</MenuItem>
						))}
					</MenuLinks>
				</HeaderInner>
			</Container>
		</Header>
	)
}

export default NavBar

import styled from 'styled-components'
import breakpoints from '../../commons/breakpoints'

const FlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;

	@media ${breakpoints.device.tablet} {
		flex-direction: row;
	}
`

export default FlexContainer

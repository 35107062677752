import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../commons/breakpoints'

const StyledHeader = styled.h2`
	position: relative;

	&:before {
		content: '';
		width: 2rem;
		height: 0.3rem;
		background: ${(props) => props.theme.colors.yellow};
		position: absolute;
		top: -1rem;
		left: 0;
	}

	@media ${breakpoints.device.tablet} {
		&:before {
			width: 3rem;
			height: 0.4rem;
			top: -1.2rem;
		}
	}
`

const SectionTitle = ({ title }) => {
	return (
		<>
			<StyledHeader>{title}</StyledHeader>
		</>
	)
}

export default SectionTitle

import React from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import '@fontsource/roboto'
import '@fontsource/roboto/700.css'
import theme from '../commons/theme'
import breakpoints from './../commons/breakpoints'
import NavBar from './NavBar'
import Footer from './Footer'
import Seo from './Seo'

// *** Global styles ***
const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
    line-height: 1.75;
  }

  body {
    color: ${({ theme }) => theme.colors.copyMain};
    font-family: roboto, 'sans-serif';
    font-size: 16px;
    text-rendering: optimizeSpeed;
	  overflow-x: hidden;
    background: ${({ theme }) => theme.colors.white};
    
  }

  #gatsby-focus-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    transition: 0.3s;
    color: inherit;

    &:hover {
      color: ${({ theme }) => theme.colors.basePurple};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5, 
  h6 {
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.deepBlue};
    margin: 0 0 1rem 0;
    font-weight: 700;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3, h4 {
    font-size: 1.3rem;
    letter-spacing: 1px;
  }



    ul {
    padding: 0;
    list-style-type: none;
  }

   
  @media ${breakpoints.device.tablet} {
    body {
      font-size: 18px;
    }

    h1 {
    font-size: 2.3rem;
  }
  h2 {
    font-size: 2.2rem;
  }

  h3, h4 {
    font-size: 1.7rem;
  }
  }  
`

const StyledMain = styled.main`
  flex: 1 0 auto;
`

// *** Layout ***
const Layout = ({ children, pageTitle }) => {
  return (
    <>
      <Seo pageTitle={pageTitle} />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <NavBar />
        <StyledMain>{children}</StyledMain>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default Layout

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import breakpoints from '../commons/breakpoints'

export const StyledButton = styled(Link)`
	padding: 0.3rem 1rem;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.basePurple};
	border: 2px solid ${(props) => props.theme.colors.basePurple};
	font-size: 0.8rem;
	font-weight: 700;
	display: inline-block;
	margin-top: 1rem;
	letter-spacing: 1px;

	&:hover {
		background: ${(props) => props.theme.colors.basePurple};
		color: ${(props) => props.theme.colors.copyLight};
	}

	@media ${breakpoints.device.tablet} {
		padding: 0.5rem 1.5rem;
		font-size: 0.9rem;
	}
`

const Button = (props) => {
	const { pathName, title } = props
	return <StyledButton to={pathName}>{title}</StyledButton>
}

export default Button

import styled from 'styled-components'
import breakpoints from '../../commons/breakpoints'

const Section = styled.section`
	padding: 4rem 0;
	background: ${({ theme, gradient }) => (gradient ? theme.gradient : 'none')};

	@media ${breakpoints.device.tablet} {
		padding: 5rem 0;
	}
`
export default Section

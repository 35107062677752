import styled from 'styled-components'
import breakpoints from '../../commons/breakpoints'

const Container = styled.div`
	max-width: ${breakpoints.size.desktop};
	margin: auto;
	padding: 0 1rem;

	@media ${breakpoints.device.tablet} {
		padding: 0 1.5rem;
	}
`

export default Container

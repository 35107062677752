export const toTitleCase = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export const validateInput = (field, value) => {
  const fieldValue = value.trim()
  const isEmpty = fieldValue === ''
  const IS_REQUIRED = 'is required'
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  switch (field) {
    case 'name':
      return isEmpty ? `Name ${IS_REQUIRED}` : null

    case 'email':
      return isEmpty
        ? `Email ${IS_REQUIRED}`
        : emailRegex.test(fieldValue)
        ? null
        : 'Invalid email address'

    case 'message':
      return isEmpty
        ? `Message ${IS_REQUIRED}`
        : fieldValue.length < 5
        ? 'Message is too short'
        : null
  }
}

export const getCurrentYear = () => new Date().getFullYear()

export const getStackDetails = (stack, stackName) =>
  stack.find((tool) => tool.name.toLowerCase() === stackName.toLowerCase())
